import "@src/styles/globals.css";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";
import { defaultStore } from "@src/lib/store";
import { Provider as ReduxProvider } from "react-redux";

const queryClient = new QueryClient();

export function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={defaultStore}>
        <Component {...pageProps} />
      </ReduxProvider>
    </QueryClientProvider>
  );
}

export default App;
